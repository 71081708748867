import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import DashboardIcon from '@/components/atoms/Icon/svg/DashboardIcon'
import { MenuItemProps } from '@/components/atoms/SelectField'
import Space from '@/components/atoms/Space'
import { ChartDataState } from '@/components/charts'
import ScopeDoughnut from '@/components/charts/Doughnut/scopeDoughnut'
import GhgStackedBar from '@/components/charts/StackedBar'
import SelectorGroup from '@/components/molecules/SelectorGroup'
import SiteSelect from '@/components/organisms/SiteSelect'
import { ALL_SITES } from '@/constant/sites'
import { organizationApi, organizationSummaryApi, siteApi, siteSummaryApi } from '@/ghgApi'
import ResponseToChartData from '@/ghgApi/ResponseToChartData'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { Objective, Site } from '@/openapi/api'
import { ResponseData } from '@/pages/analysis/organization'
import { ROUTES } from '@/routes'
import theme from '@/theme'
import { getRootSiteId } from '@/utils/siteNodeHelper'
import { SiteWithObjective } from '@/zustand/slice/storeSlice'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core'
import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import SiteInfoTable from '../Analysis/Tables/SiteInfoTable'
import PdfHeader from '../PdfHeaderRender'

const useStyles = makeStyles({
    container: {
        marginTop: 30,
        paddingBottom: 30,
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: 20,
        maxWidth: theme.contentContainer.width,
    },
    boxGHGChart: {
        minHeight: 480,
        width: '100%',
        height: '100%',
        padding: '20px',
        border: '1px solid #DDDDDD',
        display: 'flex',
        flexDirection: 'column',
    },
    stackChart: {
        height: '100%',
    },
    chartText: {
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'center',
    },
    btnWrapper: {
        marginTop: 20,
        textAlign: 'center',
    },
    grid1: {
        gridColumn: '1 / 2',
    },
    grid2: {
        gridColumn: '2 / 4',
    },
    siteTableWrapper: {
        gridColumn: '1 / 4',
        display: 'flex',
        justifyContent: 'center',
    },
    custom: {
        '-webkit-print-color-adjust': 'exact',
    },
    pageBreak: {
        pageBreakInside: 'avoid',
    },
})
const Overview = () => {
    const [responseData, setResponseData] = useState<ResponseData>()
    const [chartData, setChartData] = useState<ChartDataState>(null)
    const [objective, setObjective] = useState<Objective['text']>('')
    const [yearSelectorHeight, setYearSelectorHeight] = useState<number>(0)
    const [isPrinting, setIsPrinting] = useState(false)
    const [siteOptions, setSiteOptions] = useState<MenuItemProps[]>([])
    const promiseResolveRef = useRef<any>(null)
    const componentRef = useRef<HTMLDivElement | null>(null)
    const {
        storeState,
        analysisState,
        disabledScope3,
        emissionFactorTableNodes,
        isShowAllCompanyOption,
        expandedMenu,
        setSelectedSite,
        setTabValue,
        setAnalysisState,
        setStoreState,
    } = useStore()
    const [_, setSiteSessionStorage] = useStateWithSessionStorage<Site | null>(SessionStorageKey.SITE_SELECTOR, null)
    const classes = useStyles()
    const year = useMemo(() => {
        return analysisState.dateSelector.options.years[0] || 0
    }, [analysisState.dateSelector.options.years])
    const [selectSite, setSelectSite] = useState<MenuItemProps | null>(null)

    // =============================================================================
    // Effects
    // =============================================================================

    useEffect(() => {
        fetchOrganizationSites()
    }, [])

    useEffect(() => {
        if (isShowAllCompanyOption) {
            setSiteOptions([ALL_SITES, ...storeState.sites.map((site) => {
                return { id: site.id, value: site.name }
            })])
            setSelectSite(ALL_SITES)
        } else if (storeState.sites.length) {
            setSiteOptions(storeState.sites.map((site) => {
                return { id: site.id, value: site.name }
            }))
            const { id, name } = storeState.sites[0]
            setSelectSite({ id, value: name })
        }
    }, [isShowAllCompanyOption, storeState.sites])

    useEffect(() => {
        if (!year) return
        if (chartData) setChartData(null)
        if (selectSite?.id) {
            siteSummaryApi
                .getAnnualSiteSummary(selectSite?.id, year)
                .then((res) => {
                    setChartData({
                        doughnut: {
                            allScopes: ResponseToChartData.Doughnut.scopes(res.data),
                            scopes1: ResponseToChartData.Doughnut.scope1or2(res.data, 1, emissionFactorTableNodes),
                            scopes2: ResponseToChartData.Doughnut.scope1or2(res.data, 2, emissionFactorTableNodes),
                            scope3: ResponseToChartData.Doughnut.scope3Categories(res.data),
                        },
                        bar: {
                            allScopes: ResponseToChartData.Bar(storeState.organization?.startMonth).scopes(res.data),
                        },
                    })
                })
                .catch((err) => {
                    if (err.response?.status != 404) console.warn('OrganizationApi.getOrganizationSummaries:', err)
                })
        } else if (selectSite?.id === 0) {
            organizationSummaryApi
                .getAnnualOrganizationSummary(year)
                .then((res) => {
                    setResponseData(res.data)
                    setChartData({
                        doughnut: {
                            allScopes: ResponseToChartData.Doughnut.scopes(res.data),
                            scopes1: ResponseToChartData.Doughnut.scope1or2(res.data, 1, emissionFactorTableNodes),
                            scopes2: ResponseToChartData.Doughnut.scope1or2(res.data, 2, emissionFactorTableNodes),
                            scope3: ResponseToChartData.Doughnut.scope3Categories(res.data),
                            sites: ResponseToChartData.Doughnut.sites(res.data),
                        },
                        bar: {
                            allScopes: ResponseToChartData.Bar(storeState.organization?.startMonth).scopes(res.data),
                            sites: ResponseToChartData.Bar(storeState.organization?.startMonth).sites(res.data),
                        },
                    })
                })
                .catch((err) => {
                    if (err.response?.status != 404) console.warn('OrganizationApi.getOrganizationSummaries:', err)
                })
        }
    }, [selectSite?.id, year])

    useEffect(() => {
        if (!storeState.organization?.objectives) return
        if (chartData) setChartData(null)
        if (selectSite?.id) {
            const sites = storeState.sites as SiteWithObjective[]
            const rootSiteId = getRootSiteId(selectSite.id, sites)
            const objectives = sites.find((site) => site.id == rootSiteId)?.objectives
            setObjective(objectives?.text || '')
        } else if (selectSite?.id === 0) {
            const objectives = storeState.organization.objectives
            setObjective(objectives.text || '')
        }
    }, [selectSite?.id, storeState.sites])

    const fetchOrganizationSites = async () => {
        try {
            const res = await Promise.all([
                siteApi.listSite(undefined, undefined, 10000),
                organizationApi.getOrganizationSitesObjectives(),
            ])
            const sitesRes = res[0].data.data
            if (!sitesRes) {
                console.log('siteApi.listSite response empty-sites.')
                return
            }
            const siteObjectivesRes = res[1].data.sites
            const sitesWithObjective = [
                ...sitesRes.map((site) => {
                    return {
                        ...site,
                        objectives: siteObjectivesRes.find((r) => r.siteId === site.id)?.objectives.find((o) => year === o.year) ?? '',
                    }
                })
            ]
            setStoreState({
                ...storeState,
                sites: sitesWithObjective,
            })
            setAnalysisState({
                ...analysisState,
                sites: sitesWithObjective,
            })
        } catch (error) {
            console.warn(error)
        }
    }

    const handleSelectorGroupChange = (
        e: React.ChangeEvent<{
            name?: string | undefined
            value: unknown
        }>,
    ) => {
        const site = siteOptions.find((s) => s.id === e.target.value)
        if (site) setSelectSite(site)
    }

    const handleSiteSelectChange = (selectedSiteId: number | null) => {
        if (!selectedSiteId) {
            return
        }
        const site = storeState.sites.find(s => s.id === selectedSiteId as number)
        if (!site) {
            return
        }
        const selectSite = {id: site.id, value: site.name}
        setSelectSite(selectSite)
    }

    const onDetailClick = (tabValue: number, p?: string) => {
        setTabValue(tabValue)
        if (p) {
            navigate(p)
            return
        }
        let path: string = ''
        const isOrganization = !selectSite?.id
        if (isOrganization) path = ROUTES.ANALYSIS_ORGANIZATION
        else {
            const selectedSite: Site = {
                id: selectSite?.id,
                name: selectSite.value,
                organizationId: storeState.organization?.id as number,
                status: storeState.organization?.status,
            }
            setSelectedSite(selectedSite)
            setAnalysisState({
                ...analysisState,
                selectedSite,
            })
            setSiteSessionStorage(selectedSite)
            path = ROUTES.ANALYSIS_SITE
        }
        navigate(path)
    }

    const handlePrint = useReactToPrint({
        pageStyle: `@media print {
            @page {
              size: 450mm 630mm;
            }
          }`,
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve
                setIsPrinting(true)
            })
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null
            setIsPrinting(false)
        },
    })

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current()
        }
    }, [isPrinting])

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 1,
                    top: 70,
                    width: `calc(100% - ${expandedMenu ? 300 : 120}px)`,
                    transition: 'width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                    minWidth: '1615px'
                }}
            >
                <Space />
                {selectSite && (
                    <>
                        <SelectorGroup
                            isDashboard
                            year={year}
                            description={objective}
                            onPdfClick={handlePrint}
                            value={selectSite?.id}
                            options={siteOptions}
                            onChange={handleSelectorGroupChange}
                            setSelectSite={setSelectSite}
                            setYearSelectorHeight={setYearSelectorHeight}
                        />
                        <SiteSelect
                            sites={storeState.sites}
                            onChange={handleSiteSelectChange}
                        />
                    </>
                )}
                <Space height={15} />
            </div>
            {chartData && (
                <div style={{ marginTop: yearSelectorHeight + 90 }} className={classes.custom} ref={componentRef}>
                    {isPrinting && storeState.organization?.objectives ? (
                        <PdfHeader
                            subTitle={
                                <div>
                                    <DashboardIcon />
                                    &emsp; ダッシュボード
                                </div>
                            }
                            title={`${year}年度 ${selectSite?.value || ''}`}
                            objective={storeState.organization.objectives}
                        />
                    ) : null}
                    <div id="charts-container" className={`${classes.container} charts-container`}>
                        <div>
                            <ScopeDoughnut
                                title={'全Scope総排出量'}
                                {...chartData.doughnut.allScopes}
                                allScope
                                onDetailClick={() => onDetailClick(0)}
                                isPrinting={isPrinting}
                            />
                        </div>
                        <div className={classes.grid2}>
                            <div className={classes.boxGHGChart}>
                                <h4 className={classes.chartText}>全Scope総排出量年間推移</h4>
                                <div className={classes.stackChart}>
                                    <GhgStackedBar datasets={chartData?.bar.allScopes} isReverse />
                                </div>
                                {isPrinting ? null : (
                                    <div className={classes.btnWrapper}>
                                        <PrimaryButton
                                            width={83}
                                            height={35}
                                            fontSize={14}
                                            onClick={() => onDetailClick(0)}
                                        >
                                            詳細
                                        </PrimaryButton>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <ScopeDoughnut
                                title="Scope1のGHG排出量"
                                {...chartData.doughnut.scopes1}
                                onDetailClick={() => onDetailClick(1)}
                                isPrinting={isPrinting}
                            />
                        </div>
                        <div>
                            <ScopeDoughnut
                                title="Scope2のGHG排出量"
                                {...chartData.doughnut.scopes2}
                                onDetailClick={() => onDetailClick(2)}
                                isPrinting={isPrinting}
                            />
                        </div>
                        <div>
                            <ScopeDoughnut
                                title="Scope3のGHG排出量"
                                {...chartData.doughnut.scope3}
                                noBorder
                                onDetailClick={() => onDetailClick(3)}
                                disabled={disabledScope3}
                                isPrinting={isPrinting}
                            />
                        </div>
                        {!selectSite?.id && (
                            <>
                                <div className={classes.pageBreak}>
                                    <ScopeDoughnut
                                        title={'拠点別の全Scope総排出量'}
                                        {...chartData?.doughnut.sites}
                                        noBorder
                                        onDetailClick={() => onDetailClick(0, ROUTES.ANALYSIS_SITE)}
                                        isPrinting={isPrinting}
                                    />
                                </div>
                                <div className={`${classes.pageBreak} ${classes.grid2}`}>
                                    <div className={classes.boxGHGChart}>
                                        <h4 className={classes.chartText}>拠点別の全Scope総排出量年間推移</h4>
                                        <div className={classes.stackChart}>
                                            <GhgStackedBar datasets={chartData?.bar.sites} isReverse />
                                        </div>
                                        {isPrinting ? null : (
                                            <div className={classes.btnWrapper}>
                                                <PrimaryButton
                                                    width={83}
                                                    height={35}
                                                    fontSize={14}
                                                    onClick={() => {
                                                        // TODO: tobe improved
                                                        setSelectedSite(storeState.sites[0])
                                                        onDetailClick(0, ROUTES.ANALYSIS_SITE)
                                                    }}
                                                >
                                                    詳細
                                                </PrimaryButton>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {responseData && !isPrinting && (
                                    <div className={`${classes.siteTableWrapper} ${classes.pageBreak}`}>
                                        <SiteInfoTable siteData={responseData.sites} />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default Overview
