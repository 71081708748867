import SelectField from '@/components/atoms/SelectField'
import { Site } from '@/openapi'
import theme from '@/theme'
import { makeStyles } from '@material-ui/core'
import React, { useState, useEffect, useMemo } from 'react'

const useStyle = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        padding: '13px 15px',
        backgroundColor: theme.colors.expandBackground,
    },
    containerAnalysis: {
        backgroundColor: theme.colors.primaryN,
    },
    selectWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: 10,
    },
    selectGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        fontWeight: 300,
        fontSize: 16,
    },
    select: {
        width: 310,
    },
})

interface SiteAsOption {
    id: number,
    value: string,
    parentSiteId?: number | null
}

interface SiteSelectProps {
    sites: Site[],
    onChange: (selectedSiteId: number | null) => void
    isAnalysis?: boolean
}

const optionNothing: SiteAsOption = {
    id: 0,
    value: '選択しない'
}

export default function SiteSelect(props: SiteSelectProps) {
    const classes = useStyle()
    const [options1, setOptions1] = useState([optionNothing])
    const [options2, setOptions2] = useState([optionNothing])
    const [options3, setOptions3] = useState([optionNothing])
    const [options4, setOptions4] = useState([optionNothing])
    const [options5, setOptions5] = useState([optionNothing])
    const [selectedSiteId1, setSelectedSiteId1] = useState(0)
    const [selectedSiteId2, setSelectedSiteId2] = useState(0)
    const [selectedSiteId3, setSelectedSiteId3] = useState(0)
    const [selectedSiteId4, setSelectedSiteId4] = useState(0)
    const [selectedSiteId5, setSelectedSiteId5] = useState(0)

    const allOptions = useMemo(() => {
        return [
            optionNothing,
            ...props.sites.map(site => ({ id: site.id, value: site.name, parentSiteId: site.parentSiteId })),
        ]
    }, [props.sites])

    useEffect(() => {
        setOptions1(allOptions.filter(option => !option.parentSiteId))
    }, [allOptions])

    const handleChangeSelect1 = (e: React.ChangeEvent<any>) => {
        const value = e.target.value
        const options2 = [
            optionNothing,
            ...allOptions.filter(option => option.parentSiteId == value)
        ]
        setOptions2(options2)
        setOptions3([optionNothing])
        setOptions4([optionNothing])
        setOptions5([optionNothing])
        setSelectedSiteId1(value)
        setSelectedSiteId2(0)
        setSelectedSiteId3(0)
        setSelectedSiteId4(0)
        setSelectedSiteId5(0)
        props.onChange(value)
    }

    const handleChangeSelect2 = (e: React.ChangeEvent<any>) => {
        const value = e.target.value
        const options3 = [
            optionNothing,
            ...allOptions.filter(option => option.parentSiteId == value)
        ]
        setOptions3(options3)
        setOptions4([optionNothing])
        setOptions5([optionNothing])
        setSelectedSiteId2(value)
        setSelectedSiteId3(0)
        setSelectedSiteId4(0)
        setSelectedSiteId5(0)
        props.onChange(value)
    }

    const handleChangeSelect3 = (e: React.ChangeEvent<any>) => {
        const value = e.target.value
        const options4 = [
            optionNothing,
            ...allOptions.filter(option => option.parentSiteId == value)
        ]
        setOptions4(options4)
        setOptions5([optionNothing])
        setSelectedSiteId3(value)
        setSelectedSiteId4(0)
        setSelectedSiteId5(0)
        props.onChange(value)
    }

    const handleChangeSelect4 = (e: React.ChangeEvent<any>) => {
        const value = e.target.value
        const options5 = [
            optionNothing,
            ...allOptions.filter(option => option.parentSiteId == value)
        ]
        setOptions5(options5)
        setSelectedSiteId4(value)
        setSelectedSiteId5(0)
        props.onChange(value)
    }

    const handleChangeSelect5 = (e: React.ChangeEvent<any>) => {
        const value = e.target.value
        setSelectedSiteId5(value)
        props.onChange(value)
    }

    return (
        <div className={`${classes.container} ${props.isAnalysis ? classes.containerAnalysis : ''}`}>
            <div className={classes.selectWrapper}>
                <div className={classes.selectGroup}>
                    <SelectField
                        className={classes.select}
                        style={{ width: 310 }}
                        fullWidth
                        size="sm"
                        menuItems={options1}
                        value={selectedSiteId1}
                        onChange={(e) => handleChangeSelect1(e)}
                    />
                    <SelectField
                        className={classes.select}
                        style={{ width: 310 }}
                        fullWidth
                        size="sm"
                        menuItems={options2}
                        value={selectedSiteId2}
                        onChange={(e) => handleChangeSelect2(e)}
                    />
                    <SelectField
                        className={classes.select}
                        style={{ width: 310 }}
                        fullWidth
                        size="sm"
                        menuItems={options3}
                        value={selectedSiteId3}
                        onChange={(e) => handleChangeSelect3(e)}
                    />
                    <SelectField
                        className={classes.select}
                        style={{ width: 310 }}
                        fullWidth
                        size="sm"
                        menuItems={options4}
                        value={selectedSiteId4}
                        onChange={(e) => handleChangeSelect4(e)}
                    />
                    <SelectField
                        className={classes.select}
                        style={{ width: 310 }}
                        fullWidth
                        size="sm"
                        menuItems={options5}
                        value={selectedSiteId5}
                        onChange={(e) => handleChangeSelect5(e)}
                    />
                </div>
            </div>
        </div>
    )
}
